// This file was automatically generated from concierge.availability.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }


Nightloop.Templates.Concierge.Availability = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="conciergeavailview"><div class="availselect guests"><select class=\'guests\' name="guests" onChange="this.blur()"><option value="">select</option>');
  var choiceList5075 = opt_data.guests_options;
  var choiceListLen5075 = choiceList5075.length;
  for (var choiceIndex5075 = 0; choiceIndex5075 < choiceListLen5075; choiceIndex5075++) {
    var choiceData5075 = choiceList5075[choiceIndex5075];
    output.append('<option value="', soy.$$escapeHtml(choiceData5075[0]), '"', (choiceData5075[0] == opt_data.guests) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData5075[1]), '</option>');
  }
  output.append('</select><div class="title">Party size</div></div><div class="availselect date"><div class="val"></div><div class="title">Date</div><input class=\'avail-date\' type="hidden" name="date" value="', soy.$$escapeHtml(opt_data.date), '" ></div><div class="avail-date-calendar-container no-display"><div  class=\'avail-date-calendar\'></div></div><div class="availselect time"><select class=\'time_slot\' name="time_slot" onChange="this.blur()"><option value="">select</option>');
  var choiceList5089 = opt_data.time_options;
  var choiceListLen5089 = choiceList5089.length;
  for (var choiceIndex5089 = 0; choiceIndex5089 < choiceListLen5089; choiceIndex5089++) {
    var choiceData5089 = choiceList5089[choiceIndex5089];
    output.append('<option value="', soy.$$escapeHtml(choiceData5089), '" ', (choiceData5089 == opt_data.time_slot) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(choiceData5089), '</option>');
  }
  output.append('</select><div class="title">Time</div></div><div class="availbutton">search</div><div class="float-end"></div><div class="results no-display"><div class="heading">AVAILABILITY ON <span class="searched-date"></span>&nbsp; AROUND <span class="searched-timeslot">', soy.$$escapeHtml(opt_data.time_slot), '</span>&nbsp; FOR <span class="searched-guests">', soy.$$escapeHtml(opt_data.guests), '</span> PEOPLE</div><div class="rows"><div class="slot s-0"></div><div class="slot s-1"></div><div class="slot s-2"></div><div class="slot s-3"></div><div class="slot s-4"></div><div class="slot s-5"></div><div class="slot s-6"></div></div><div><div class="no-results no-display float-left">NO AVAILABILITY FOUND</div>', (opt_data.can_custom_dining_request) ? '<div class="submit-request"><div class="desc">Can\'t find what you\'re looking for?</div><div class="req"><a href="javascript:void(0);" class="custom-reservation-popup-link custom-request-btn" venue_name="' + soy.$$escapeHtml(opt_data.venue_name) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" >request</a></div><div class="float-end"></div></div>' : '', '<div class="float-end"></div></div><div class="float-end"></div></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AvailabilityRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="row"><div class="date ', (opt_data.is_search) ? 'searched' : '', '"><div>', soy.$$escapeHtml(opt_data.date_formatted), '</div></div><div class="times">');
  if (opt_data.is_empty_code == 'VENUE_CLOSED') {
    output.append('<div class="empty_code">', soy.$$escapeHtml(opt_data.venue_name), ' is closed on this date</div>');
  } else if (opt_data.is_empty_code == 'NO_RESULTS') {
    output.append('<div class="empty_code">No availability found</div>');
  } else {
    var timeslotList5129 = opt_data.time_slots;
    var timeslotListLen5129 = timeslotList5129.length;
    for (var timeslotIndex5129 = 0; timeslotIndex5129 < timeslotListLen5129; timeslotIndex5129++) {
      var timeslotData5129 = timeslotList5129[timeslotIndex5129];
      Nightloop.Templates.Concierge.AvailabilityOption({base_url: opt_data.base_url, venue_class: opt_data.venue_class, venue_id: opt_data.venue_id, max_guests: opt_data.max_guests, reservation_id: opt_data.reservation_id, date_url: opt_data.date_url, timeslot: timeslotData5129, previous_reservation_id: opt_data.previous_reservation_id, isLast: timeslotIndex5129 == timeslotListLen5129 - 1}, output);
    }
  }
  output.append('<div class="float-end"></div></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.UpcomingAvailability = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="upcoming-avail">');
  var party_sizeList5144 = soy.$$getMapKeys(opt_data.avail_by_party_size);
  var party_sizeListLen5144 = party_sizeList5144.length;
  for (var party_sizeIndex5144 = 0; party_sizeIndex5144 < party_sizeListLen5144; party_sizeIndex5144++) {
    var party_sizeData5144 = party_sizeList5144[party_sizeIndex5144];
    output.append('<div class="title">Next available tables for ', soy.$$escapeHtml(party_sizeData5144), '</div><div class="results">');
    var availList5148 = opt_data.avail_by_party_size[party_sizeData5144];
    var availListLen5148 = availList5148.length;
    for (var availIndex5148 = 0; availIndex5148 < availListLen5148; availIndex5148++) {
      var availData5148 = availList5148[availIndex5148];
      var timeslotList5149 = availData5148.search_result.timeslots;
      var timeslotListLen5149 = timeslotList5149.length;
      for (var timeslotIndex5149 = 0; timeslotIndex5149 < timeslotListLen5149; timeslotIndex5149++) {
        var timeslotData5149 = timeslotList5149[timeslotIndex5149];
        Nightloop.Templates.Concierge.AvailabilityOption({base_url: opt_data.base_url, venue_class: opt_data.venue_class, venue_id: opt_data.venue_id, max_guests: availData5148.max_guests, reservation_id: availData5148.reservation_id, date_url: availData5148.date_url, timeslot: timeslotData5149, dateFormatted: availData5148.dateFormatted, isLast: timeslotIndex5149 == timeslotListLen5149 - 1}, output);
      }
    }
    output.append((opt_data.can_custom_dining_request) ? '<div class="req"><a href="javascript:void(0);" class="custom-reservation-popup-link custom-request-btn" venue_name="' + soy.$$escapeHtml(opt_data.venue_name) + '" venue_id="' + soy.$$escapeHtml(opt_data.venue_id) + '" >request</a></div>' : (! opt_data.has_avail_by_party_size[party_sizeData5144]) ? 'No availability' : '', '<div class="float-end"></div></div>');
  }
  output.append('</div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.AvailabilityOption = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="booking-option ', (! opt_data.timeslot.time_slot) ? 'empty' : '', ' ', (opt_data.isLast) ? 'last' : '', ' ', (opt_data.dateFormatted) ? 'has-date' : '', '">', (opt_data.timeslot.time_slot) ? '<form class="ajaxify" method="GET" action="' + soy.$$escapeHtml(opt_data.base_url) + '/res/enter-client-info/' + soy.$$escapeHtml(opt_data.reservation_id) + '"><input type="hidden" name="venue_class" value="' + soy.$$escapeHtml(opt_data.venue_class) + '" /><input type="hidden" name="venue_id" value="' + soy.$$escapeHtml(opt_data.venue_id) + '" /><input type="hidden" name="time_slot" value="' + soy.$$escapeHtml(opt_data.timeslot.time_slot) + '" /><input type="hidden" name="date" value="' + soy.$$escapeHtml(opt_data.date_url) + '" /><input type="hidden" name="max_guests" value="' + soy.$$escapeHtml(opt_data.max_guests) + '" /><input type="hidden" name="access_persistent_id" value="' + soy.$$escapeHtml(opt_data.timeslot.access_persistent_id) + '" />' + ((opt_data.previous_reservation_id) ? '<input type="hidden" name="previous_reservation_id" value="' + soy.$$escapeHtml(opt_data.previous_reservation_id) + '" />' : '') + '<a class="booking-option-link" href="javascript:void(0);" onclick="$(this).closest(\'form\').submit();">' + ((opt_data.dateFormatted) ? '<span class="book-date">' + soy.$$escapeHtml(opt_data.dateFormatted) + '</span>' : '') + '<span class="book-time">' + soy.$$escapeHtml(opt_data.timeslot.time_display) + '</span></a></form>' : '&nbsp;', '</div>');
  return opt_sb ? '' : output.toString();
};
